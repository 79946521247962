module.exports = {
  defaultLocale: "en",
  locales: [
    "af",
    "ar",
    "be",
    "bd",
    "mm",
    "cn",
    "de",
    "el",
    "en",
    "fr",
    "hi",
    "id",
    "it",
    "jp",
    "kh",
    "ko",
    "my",
    "no",
    "nl",
    "pa",
    "ph",
    "pt",
    "ru",
    "sp",
    "th",
    "sv",
    "tr",
    "tw",
    "uk",
    "vi",
  ],
  // localeDetection: true,
};
